import React from 'react'
import { LayoutA } from '../layouts'
import { Screenshots } from '../components'

class HomePage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <LayoutA className="HomePage" title={"Homepage"}>

        <div className="hero">
          <div className="hero-background"></div>
          <div className="hero-copy">
            <h1>
              Church organization made easy!
            </h1>
            <p>
              You'll love it ;D
            </p>
          </div>
          <div className="hero-screenshot">
            <img src="https://via.placeholder.com/1000x600" />
          </div>
        </div>
        <div className="screenshots">
          <Screenshots imagesPerRow={3} images={[
            { src: 'https://via.placeholder.com/1800x1200' },
            { src: 'https://via.placeholder.com/1800x1200' },
            { src: 'https://via.placeholder.com/1800x1200' }
          ]} />
        </div>

      </LayoutA>
    )
  }

}

export default HomePage;
